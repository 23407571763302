<template>
	<div class="page page-detail">
		<Menu />
		
		<div class="content-wrapper">
			<Gallery :thumbnail="thumbnail" :gallery="gallery" :count="galleryCount" :alt="title" />
			
			<div class="container">
				<Breadcrumb :title="title" />
				
				<div class="top-info">
					<div class="tags-wrapper">
						<div v-if="tagTop" class="tag tag-red">Top</div>
						<div v-if="tagNew" class="tag tag-green">Novinka</div>
						<div class="tag tag-yellow">{{ type }}</div>
					</div>
					
					<div class="reality-id">ID inzerátu: {{ premiseID }}</div>
				</div>
				
				<div class="title-wrapper">
					<h1>{{ title }}</h1>
					<div class="price">{{ formatPrice }} <span>s DPH</span></div>
				</div>
				
				<div class="subtitle-wrapper">
					<div class="address-wrapper">
						<div class="icon">
							<img src="../assets/icons/adresa_gray.png" alt="adresa">
						</div>
						<div class="inner">
							<div class="address">{{ location }}</div>
							<a :href="'https://maps.google.com/?q=' + location" target="_blank">Zobraziť mapu</a>
						</div>
					</div>
					
					<div class="social-icons">
						<a :href="shareFacebook" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-facebook-f fa-fw"></i>
							</div>
						</a>
<!--						<a href="#">-->
<!--							<div class="icon">-->
<!--								<i class="fa-brands fa-instagram fa-fw"></i>-->
<!--							</div>-->
<!--						</a>-->
						<a href="#print" @click="printPage">
							<div class="icon">
								<img src="../assets/icons/print.png" alt="tlac">
							</div>
						</a>
					</div>
				</div>
				
				<div class="row">
					<div class="reality-info col-lg-8">
						<div class="description-wrapper white-box">
							<h2>Info</h2>
							<div class="description" v-html="description"></div>
						</div>
						
						<div class="params-wrapper white-box">
							<h2>Charakteristika</h2>
							
							<div class="params">
								<div class="params-list">
									<div class="param-item" v-for="item in leftParams" :key="item.id">
										<div class="param-title">{{ item.title }}:</div>
										<div class="param-value">{{ formatParamValue(item.values) }}</div>
									</div>
								</div>
								<div class="params-list">
									<div class="param-item" v-for="item in rightParams" :key="item.id">
										<div class="param-title">{{ item.title }}:</div>
										<div class="param-value">{{ formatParamValue(item.values) }}</div>
									</div>
<!--									<div class="param-item">-->
<!--										<div class="param-title">Elektrický prúd:</div>-->
<!--										<div class="param-value">len 220 V</div>-->
<!--									</div>-->
<!--									<div class="param-item">-->
<!--										<div class="param-title">Internet:</div>-->
<!--										<div class="param-value">áno</div>-->
<!--									</div>-->
								</div>
							</div>
						</div>
					</div>
					
					<div class="reality-contact-form col-lg-4">
						<div class="white-box">
							<p class="author">{{ portalCompany }}</p>
							<p class="author-page-link">
								<router-link :to="{name: 'Home'}">Zobraziť všetky inzeráty</router-link>
							</p>
							<div class="author-phone">
								<div class="icon">
									<img src="../assets/icons/phone.png" alt="telefon">
								</div>
								
								<a :href="'tel:' + phone">{{ phone }}</a>
							</div>
							
							<div class="author-contact-form" :class="{'is-hidden': contactFormThankYou}">
								<b-form-input type="text" placeholder="Meno" v-model="contactForm.name" :state="$v.contactForm.name.$error ? false : null"></b-form-input>
								<b-form-input type="email" placeholder="E-mail" v-model="contactForm.email" :state="$v.contactForm.email.$error ? false : null"></b-form-input>
								<b-form-input type="tel" placeholder="Tel. číslo" v-model="contactForm.phone" :state="$v.contactForm.phone.$error ? false : null"></b-form-input>
								<b-form-textarea placeholder="Dobrý deň, zaujímam sa o ..." rows="8" v-model="contactForm.message" :state="$v.contactForm.message.$error ? false : null"></b-form-textarea>
								
								<input type="checkbox" id="author_contact_gdpr" :checked="contactForm.gdpr" @change="contactForm.gdpr = $event.target.checked">
								<label class="custom-check" for="author_contact_gdpr">Súhlasím s podmienkami <a href="/clanok/gdpr" target="_blank">GDPR</a></label>
								<span v-if="$v.contactForm.gdpr.$error" class="error-message">Pre odoslanie formulára musíte súhlasiť s GDPR</span>
								
								<b-button variant="primary" @click="submitForm">Odoslať</b-button>
								<span v-if="$v.contactForm.$error || contactFormError.length > 0" class="error-message">{{ contactFormError }}</span>
							</div>
							
							<div class="contact-form-thank-you" :class="{'is-visible': contactFormThankYou}">
								<div class="custom-icon-check"></div>
								<p>Vaša správa bola úspešne odoslaná manažérom našich nehnutelností.</p>
							</div>
						</div>
					</div>
				</div>
				
				<div v-if="lastViewed.length > 0" class="last-viewed">
					<h2>Posledné prezerané</h2>
					
					<div class="list">
						<div class="row">
							<div class="col-lg-4" v-for="item in lastViewed" :key="item.id">
								<Reality :premise="item" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<Footer />
	</div>
</template>

<script>
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Gallery from "../components/Gallery";
import Breadcrumb from "../components/Breadcrumb";
import Reality from "../components/single/Reality";

import {required, email, minLength, maxLength, sameAs} from "vuelidate/lib/validators"

export default {
	components: {Reality, Breadcrumb, Gallery, Menu, Footer},
	validations: {
		contactForm: {
			name: {
				required,
				minLength: minLength(2),
				maxLength: maxLength(512)
			},
			email: {
				required,
				email,
				minLength: minLength(3),
				maxLength: maxLength(512)
			},
			phone: {
				required,
				minLength: minLength(10),
				maxLength: maxLength(14)
			},
			message: {
				required,
				minLength: minLength(2),
				maxLength: maxLength(512)
			},
			gdpr: {
				sameAs: sameAs(() => true)
			}
		}
	},
	data() {
		return {
			premise: {},
			lastViewed: [],
			
			contactForm: {
				name: '',
				email: '',
				phone: '',
				message: '',
				gdpr: false,
			},
			contactFormError: '',
			contactFormThankYou: false,
			
			plainDescription: '',
		}
	},
	mounted() {
		this.getPremise()
	},
	watch: {
		'$route.params.slug': {
			handler: function (val) {
				if (!this.premise) return
				
				if (val !== this.premise.slug) {
					this.getPremise()
				}
			}
		}
	},
	// updated() {
	// 	console.warn(this.$route.params.slug, this.premise.slug)
	// 	if (!this.premise) return
	// 	if (this.$route.params.slug === this.premise.slug) return
	// 	this.getPremise()
	// },
	methods: {
		submitForm() {
			console.warn('submit form')
			
			this.$v.$touch()
			if (this.$v.$invalid) {
				console.warn('error')
				
				this.contactFormError = 'Chýbajúce údaje, skontrolujte formulár a skúste to znova'
				
			} else {
				this.contactFormError = ''
				
				let formData = JSON.parse(JSON.stringify(this.contactForm))
				
				let body = {
					'name': formData.name,
					'email': formData.email,
					'phone': formData.phone,
					'text': formData.message,
				}
				
				const portalId = this.$store.getters.portal?.id
				
				const url = `${this.$config.API_URL}portal/${portalId}/item/${this.premise.id}/form`
				
				console.warn(body, url)
				
				this.$axios.post(url, body)
					.then((res) => {
						console.warn('get detail', res.data)
						if (res.data.message.type === "success") {
							
							this.contactFormThankYou = true
						} else {
							this.contactFormError = res.data.message.message
						}
					})
				
			}
			
		},
		getPremise() {
			const portalId = this.$store.getters.portal?.id
			
			const url = `${this.$config.API_URL}portal/${portalId}/item/${this.$route.params.slug}`
			
			this.$axios.get(url)
				.then((res) => {
					console.warn('get detail', res.data)
					const premise = res.data.data
					this.premise = premise
					this.$store.dispatch('lastVisited', premise.id)
					this.getLastVisited()
					
					this.$nextTick(() => {
						this.getPlainDescription()
					})
				})
		},
		getLastVisited() {
			const portalId = this.$store.getters.portal?.id
			
			const url = `${this.$config.API_URL}portal/${portalId}/items-by-id`
			
			const notCurrent = this.$store.getters.lastVisited?.filter(item => item !== this.premise.id)
			
			const body = {
				premise_id: notCurrent
			}
			
			this.$axios.post(url, body)
			.then((res) => {
				console.warn('get last visited', res.data)
				const lastViewed = res.data.data
				if (lastViewed) {
					this.lastViewed = res.data.data
				}
				
				// console.warn('last viewed get', lastViewed)
				
				const filteredLast = lastViewed.map((item, index) => {
					if (index < 3) {
						return item.id
					} else {
						return false
					}
				})
				
				// console.warn('filtered last', filteredLast)
				
				const current = JSON.parse(JSON.stringify(this.premise.id))
				filteredLast.unshift(current)
				
				console.warn('update last', filteredLast)
				
				this.$store.dispatch('setLastVisited', filteredLast)
			})
		},
		formatParamValue(values) {
			let result = ''
			values.map((item) => {
				if (result.length > 0) {
					result += ', ' + item.value
				} else {
					result = item.value
				}
			})
			return result
		},
		getPlainDescription() {
			this.plainDescription = document.querySelector('.description').innerText
		},
		printPage() {
			window.print()
		}
	},
	computed: {
		title: {
			get() {
				return this.premise?.title
			}
		},
		portalCompany: {
			get() {
				return this.$store.getters.portal?.address?.name
			}
		},
		phone: {
			get() {
				return this.premise?.manager?.phone
			}
		},
		type: {
			get() {
				return this.premise?.type?.title
			}
		},
		url() {
			return window.location.href
		},
		location: {
			get() {
				const address = `${this.premise?.address?.street}, ${this.premise?.address?.city}`
				return address
			}
		},
		description: {
			get() {
				return this.premise?.description ? `${this.premise?.description}` : ''
			}
		},
		premiseID: {
			get() {
				return `${this.premise?.number}`
			}
		},
		tagTop: {
			get() {
				if (!this.premise?.top_to) return false
				const toDate = this.$moment(this.premise?.top_to).valueOf()
				const currDate = this.$moment(new Date()).valueOf()
				return currDate < toDate
			}
		},
		tagNew: {
			get() {
				if (!this.premise?.new_to) return false
				const toDate = this.$moment(this.premise?.new_to).valueOf()
				const currDate = this.$moment(new Date()).valueOf()
				return currDate < toDate
			}
		},
		formatPrice: {
			get() {
				const price = this.premise?.price_with_tax;
				const value = (Math.trunc(Number(price) * 100) / 100).toLocaleString();
				return value + ' €';
			}
		},
		leftParams: {
			get() {
				const raw = this.premise?.parameters
				if (!raw) return
				const params = Object.values(raw)
				
				let max = (params.length / 2) + 1
				if (params.length === 1) {
					return params
				}
				
				return params.slice(0, max)
			}
		},
		rightParams: {
			get() {
				const raw = this.premise?.parameters
				if (!raw) return
				const params = Object.values(raw)
				if (params.length === 1) return [];
				
				const min = (params.length / 2) + 1
				const max = params.length
				return params.slice(min, max)
			}
		},
		thumbnail: {
			get() {
				return this.premise?.photo
			}
		},
		gallery: {
			get() {
				return this.premise?.photos
			}
		},
		galleryCount: {
			get() {
				return this.premise?.photo_count
			}
		},
		shareFacebook: {
			get() {
				return `https://www.facebook.com/sharer/sharer.php?u=https://${window.location.host}/detail/${this.premise?.slug}`
			}
		},
		
		defaultMetaTitle() {
			return this.$store.getters.portal?.portal?.meta?.title
		},
		
		metaTitle() {
			if (this.title?.length > 0) {
				return this.title
			} else {
				return 'Detail'
			}
			// return 'Detail'
		},
		metaDescription() {
			const innerText = this.plainDescription
			// console.warn(innerText)
			if (innerText.length > 0) {
				return innerText.slice(0, 150)
			} else {
				return this.$store.getters.portal?.portal?.meta?.description
			}
		}
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: [
				{name: 'description', content: this.metaDescription},
				{property: 'og:title', content: this.metaTitle, template: chunk => chunk ? `${chunk} | ${this.defaultMetaTitle}` : this.defaultMetaTitle},
				{property: 'og:description', content: this.metaDescription},
				{property: 'og:image', content: this.thumbnail},
				{property: 'og:url', content: this.url},
				{property: 'og:type', content: 'website'}
			],
		}
	}
}
</script>

<style lang="scss" scoped>
.page-detail::v-deep {
	.content-wrapper {
		background: $bg-color;
		
		.top-info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 30px;
			
			.tags-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-right: 10px;
			}
			
			.reality-id {
				color: $text-light;
				font-size: 13px;
			}
		}
		
		.title-wrapper {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			
			h1 {
				color: $black;
				font-size: 30px;
				font-weight: 600;
				margin-right: 50px;
			}
			
			.price {
				color: var(--reality-portal-color);
				font-size: 30px;
				font-weight: 800;
				white-space: nowrap;
				
				span {
					color: $text-light-gray;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
		
		.subtitle-wrapper {
			display: flex;
			flex-direction: row;
			margin: 20px 0 30px;
			width: 100%;
			
			.address-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				
				.icon img {
					width: 25px;
					height: auto;
				}
				
				.address {
					color: $text-light-gray;
					margin: 0 15px 0 10px;
				}
				
				.inner {
					display: flex;
					flex-direction: row;
					padding-left: 10px;
				}
				
				@media (max-width: 768px) {
					align-items: flex-start;
					
					.inner {
						flex-direction: column;
						padding-left: 10px;
						
						.address {
							margin: 0;
						}
					}
				}
			}
			
			.social-icons {
				display: flex;
				flex-direction: row;
				margin-left: auto;
				
				a {
					color: $text-light-gray;
					font-size: 20px;
					margin: 0 4px;
					transition: color .2s ease-in-out;
					
					&:hover {
						color: var(--reality-portal-color);
					}
					
					.icon {
						padding: 5px;
						background: white;
						
						img {
							width: 18px;
							height: 18px;
							object-fit: contain;
							margin: 0 4px;
						}
					}
				}
			}
		}
		
		.white-box {
			background: white;
			padding: 30px;
			border-radius: 10px;
			
			margin-bottom: 30px;
		}
		
		.description-wrapper, .params-wrapper {
			h2 {
				font-size: 20px;
				font-weight: 800;
			}
		}
		
		.description-wrapper {
			margin-bottom: 30px;
			
			.description {
				//color: $text-light-gray;
				line-height: 1.8;
			}
		}
		
		.params-wrapper {
			.params {
				display: flex;
				flex-direction: row;
				
				.params-list {
					display: flex;
					flex-direction: column;
					flex-basis: 50%;
					
					@media (min-width: 768px) {
						padding-bottom: 15px;

						&:first-child {
							border-right: 1px solid #ccc;
							padding-right: 15px;
						}

						&:last-child {
							padding-left: 15px;
						}
					}
					//@include media-breakpoint-up(lg) {
					//	padding-bottom: 15px;
					//
					//	&:first-child {
					//		border-right: 1px solid #ccc;
					//		padding-right: 15px;
					//	}
					//
					//	&:last-child {
					//		padding-left: 15px;
					//	}
					//}
					
					.param-item {
						display: flex;
						flex-direction: row;
						padding: 10px 0;
						border-bottom: 1px solid #ccc;
						
						.param-title {
							display: flex;
							//flex-basis: 70%;
							flex-basis: 50%;
							color: $text-darker-gray;
							font-size: 14px;
							padding-right: 10px;
						}
						
						.param-value {
							display: flex;
							//flex-basis: 30%;
							flex-basis: 50%;
							font-size: 14px;
						}
					}
				}
				
				@media (max-width: 768px) {
					flex-direction: column;
					
					.params-list {
						flex-basis: 100%;
					}
				}
			}
		}
		
		.author {
			font-size: 24px;
			font-weight: 800;
			margin-bottom: 5px;
			
			&-page-link {
				font-size: 14px;
			}
			
			&-phone {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 30px 0;
				
				.icon img {
					width: 23px;
				}
				
				a {
					//color: $dark;
					margin-left: 10px;
				}
			}
		}
		
		.author-contact-form {
			max-height: 600px;
			transition: all .2s ease-in-out;
			overflow: hidden;
			margin: -5px;
			padding: 5px;
			
			&.is-hidden {
				max-height: 0;
				margin: 0;
			}
			
			input:not([type=checkbox]) {
				border-radius: 30px;
				padding: 10px 20px;
				font-size: 13px;
				height: auto;
				margin-bottom: 20px;
			}
			
			textarea {
				border-radius: 10px;
				padding: 10px 20px;
				font-size: 13px;
				margin-bottom: 20px;
			}
			
			input[type=checkbox] {
				display: none;
				
				&:checked + .custom-check {
					&:after {
						transform: scale(1);
					}
				}
			}
			
			.custom-check {
				position: relative;
				display: block;
				padding-left: 20px;
				//margin-bottom: 20px;
				margin-bottom: 0;
				color: $text-gray;
				font-size: 13px;
				user-select: none;
				
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 16px;
					height: 16px;
					border: 1px solid $border-custom-color;
					border-radius: 50%;
				}
				
				&:after {
					content: '';
					position: absolute;
					top: 4px;
					left: 4px;
					width: 8px;
					height: 8px;
					background: var(--reality-portal-color);
					border-radius: 50%;
					transform: scale(0);
					transition: transform .2s ease-in-out;
				}
			}
			
			.btn {
				display: flex;
				flex-direction: row;
				justify-content: center;
				width: 100%;
				border-radius: 30px;
				text-align: center;
				font-weight: 500;
				text-transform: uppercase;
				font-size: 17px;
				height: auto;
				padding: 8px 20px;
				margin-top: 20px;
			}
			
			.error-message {
				color: red;
				font-size: 12px;
			}
		}
		
		.contact-form-thank-you {
			max-height: 0;
			transition: max-height .2s ease-in-out;
			overflow: hidden;
			
			&.is-visible {
				max-height: 500px;
			}
			
			p {
				text-align: center;
			}
			
			.custom-icon-check {
				display: flex;
				justify-content: center;
				margin: 20px 0;
				
				&::before {
					content: '';
					border: solid red;
					border-width: 0 13px 13px 0;
					width: 50px;
					height: 100px;
					display: flex;
					transform: rotate(45deg);
					transform-origin: center;
				}
			}
		}
		
		.last-viewed {
			padding: 150px 0 150px 0;
			
			h2 {
				color: var(--reality-portal-color);
				font-size: 34px;
				font-weight: 800;
				margin-bottom: 60px;
			}
		}
	}
	
	
}
</style>