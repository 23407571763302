<template>
	<div class="gallery-wrapper">
		<div v-if="count < 3" class="simple-gallery">
			<div class="item">
				<a data-fancybox="gallery" :href="thumbnail">
					<img :src="thumbnail" :alt="alt">
				</a>
			</div>
			<div class="item" v-for="photo in formattedGallery" :key="photo.id">
				<a data-fancybox="gallery" :href="photo.url">
					<img :src="photo.url" :alt="alt">
				</a>
			</div>
		</div>
		<div v-if="count > 2" class="gallery">
			<div class="first">
				<a data-fancybox="gallery" :href="thumbnail">
					<img :src="thumbnail" :alt="alt">
				</a>
			</div>
			<div class="others">
				<div class="item" v-for="(photo, index) in formattedGallery" :key="photo.id">
					<a data-fancybox="gallery" :href="photo.url">
						<img :src="photo.url" :alt="alt">
					</a>
					
					<div v-if="index === 3 && moreCount !== 0" class="more-overlay">
						<p class="count">+{{ moreCount }}</p>
						<p>ďalšie fotky</p>
					</div>
				</div>
<!--				<img src="https://www.thepinnaclelist.com/wp-content/uploads/2013/11/02-Sea-Towers-Luxury-Apartment-Gdynia-Poland.jpg" alt="">-->
			</div>
		</div>
	</div>
</template>

<script>
import {Fancybox} from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css";

export default {
	props: ['thumbnail', 'gallery', 'count', 'alt'],
	watch: {
		gallery: {
			handler: function (val) {
				if (val) {
					this.initFancy()
				}
			}
		}
	},
	methods: {
		initFancy() {
			Fancybox.bind("[data-fancybox]", {
			
			})
		}
	},
	computed: {
		formattedGallery: {
			get() {
				return this.gallery.map((item, index) => {
					return {
						id: index,
						url: item
					}
				})
			}
		},
		moreCount: {
			get() {
				return this.count > 5 ? this.count - 5 : 0
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.gallery-wrapper::v-deep {
	position: relative;
	display: flex;
	overflow: hidden;
	
	a {
		width: 100%;
		height: 100%;
	}
	
	.gallery {
		position: relative;
		display: flex;
		flex-direction: row;
		margin: -2.5px;
	}
	
	.simple-gallery {
		display: flex;
		flex-direction: row;
		margin: -2.5px;
		
		.item {
			position: relative;
			display: flex;
			flex-basis: calc(50% - 5px);
			aspect-ratio: 16/12;
			margin: 2.5px;
		}
	}
	
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.first {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-basis: calc(50% - 2.5px);
		margin: 2.5px;
	}
	
	.others {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-basis: calc(50% - 2.5px);
		
		.item {
			position: relative;
			display: flex;
			flex-basis: calc(50% - 5px);
			aspect-ratio: 16/12;
			margin: 2.5px;
			
			&:nth-child(n+5) {
				display: none;
			}
			
			.more-overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,0.5);
				color: white;
				justify-content: center;
				align-items: center;
				display: flex;
				flex-direction: column;
				user-select: none;
				pointer-events: none;
				
				.count {
					font-size: 42px;
					margin-bottom: 0;
				}
			}
		}
	}
}
</style>