<template>
	<div class="breadcrumb-wrapper">
		<b-breadcrumb class="custom-breadcrumb" :items="items"></b-breadcrumb>
	</div>
</template>

<script>
export default {
	props: ['title'],
	// data() {
	// 	return {
	// 		items:
	// 	}
	// },
	computed: {
		items: {
			get() {
				return [
					{
						text: 'Domov',
						to: {name: 'Home'}
					},
					{
						text: this.title,
						active: true
					}
				]
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.breadcrumb-wrapper::v-deep {
	.custom-breadcrumb {
		//background: none;
		margin-top: 20px;
		margin-bottom: 50px;
		padding-left: 0 !important;
		background: none !important;
		
		.breadcrumb-item {
			font-size: 14px;
			
			a {
				color: $text-darker-gray;
			}
			
			&.active {
				color: $primary;
			}
			
			+ .breadcrumb-item::before {
				content: "";
				border: solid #474545;
				border-width: 1px 1px 0 0;
				width: 5px;
				height: auto;
				aspect-ratio: 1/1;
				transform: rotate(45deg) translate(4px, 6px);
				margin-right: 0.5rem;
				padding: 0;
			}
		}
	}
}
</style>